$primary: #8951A0; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

 @import url('https://fonts.googleapis.com/css?family=Homemade+Apple');



body {
  font-size: 22px;
}

h1, h2, h3, h4, h5 {
  color: #8951A0;
  font-weight: bold;
  font-family: 'Homemade Apple', cursive;
}



body {
	font-size: 22px;
}

h1,h2,h3,h4,h5 {
	color: $primary;
	font-weight: bold;
}

h2 {
	font-size: 40px;
}

h3 {
	font-size: 22px;
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 40px;
		    display: inline-block;
		    font-size: 0.75em;
		}

	    &:hover {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	    &:focus {
		  background: transparent;
		  color: #777;
		  outline: 0;
		}

	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


@media(max-width: 767px) {
  .navbar-header a {
    float: left;
  } 
}







/** STYLES **/
/** HERO CSS **/
div.jumbotron.hero {
  color:#fff;
  text-align:center;
  background:url('../../img/hero-background-food.jpg');
  background-size:cover;
  background-position:center;

	h2 {
		font-family: arial,sans-serif;
	}

	.hero-title {
		font-size: 3.5em;
		@media (max-width: 768px) {
			font-size: 2em;
		}
	}

  .btn {
  	background: $primary;
  	text-shadow: none;
  	box-shadow: none;
	font-size: 22px;
  }
}

h1.hero-title, h2.hero-title, h3.hero-title {
  text-shadow:0 3px 3px rgba(0,0,0,0.4);
  color:#fff;
}

.hero-subtitle {
	font-size: 1.5em;
	font-weight: 400;
	letter-spacing: 1px;
	line-height: 1.5em;
	margin:25px auto;
  	color:#fff;

	@media (max-width: 991px) {
		font-size: 1em;
	}

}




/** HIGLIGHT **/
.highlight {
  color: $wht;
  background-color: $primary;
  padding:50px 0;

	 p {
	  color: $wht;
	  line-height:1.5;
	}
	
	h2 {
	  margin-bottom:25px;
	  line-height:1.5;
	  padding-top:0;
	  margin-top:0;
	  color: $wht;
	}

	.intro {
	  margin:0 auto 25px;
	}

}
.buttons {
  text-align:center;

	.btn {
	  padding:16px 32px;
	  margin:6px;
	  border:none;
	  background:none;
	  box-shadow:none;
	  text-shadow:none;
	  opacity:0.9;
	  text-transform:uppercase;
	  font-weight:bold;
	  font-size: 22px;
	  letter-spacing:0.4px;
	  line-height:1;
		&:hover {
		  opacity:1;
		}
		
		&:active {
		  transform:translateY(1px);
		}
	}


	.btn-primary, .btn-primary:active {
	  border:2px solid rgba(255,255,255,0.7);
	  border-radius:6px;
	  color:#ebeff1;
	  box-shadow:none;
	  text-shadow:none;
	  padding:14px 24px;
	  background:transparent;
	  transition:background-color 0.25s;
	}

}






/** PROJECTS **/
.projects-horizontal {
  color:#313437;
  background-color:#fff;
}

.projects-horizontal p {
  color:#7d8285;
}

.projects-horizontal h2 {
  margin-bottom:40px;
  padding-top:40px;
  color:inherit;
}

@media (max-width:767px) {
  .projects-horizontal h2 {
    margin-bottom:25px;
    padding-top:25px;

  }
}

.projects-horizontal .intro {
  // max-width:500px;
  margin:0 auto 10px;
}

.projects-horizontal .projects {
  padding-bottom:40px;
}

.projects-horizontal .item {
  padding-top:60px;
  min-height:160px;
}

@media (max-width:767px) {
  .projects-horizontal .item {
    padding-top:40px;
    min-height:160px;
  }
}

.projects-horizontal .item .name {
  margin-top:10px;
  margin-bottom:15px;
}

@media (max-width:991px) {
  .projects-horizontal .item .name {
    margin-top:22px;
  }
}

.projects-horizontal .item .description {
  margin-bottom:0;
}


/** STYLES CSS **/
.skew {
	transform:skewY(-3deg);
	p,h1,h2,h3,h4 {
		transform:skewY(3deg);
	}

	a {
		transform:skewY(0deg);
	}

}

nav.navbar.navbar-default {
  z-index:10;
  border-radius:0px;
}

.jumbotron.hero {
  margin-top:-6em;
  padding-top: 14em;
  padding-bottom: 10em;

	@media (max-width: 767px) {
		padding-top: 12em;
		padding-bottom: 4em;
	}

}


/** TEAM GRID **/
.team-grid {
  background-color:#fff;
}

.team-grid p {
  color:#7d8285;
}

.team-grid h2 {
  margin-bottom:40px;
  padding-top:40px;
}

@media (max-width:767px) {
  .team-grid h2 {
    margin-bottom:25px;
    padding-top:25px;

  }
}

.team-grid .intro {
  margin:0 auto;
}

.team-grid .intro p {
  margin-bottom:0;
}

.team-grid .people {
  padding:50px 0;
}

.team-grid .item {
  margin-bottom:30px;
}

.team-grid .item .box {
  text-align:center;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  height: 350px;
  position:relative;
  overflow:hidden;

	@media (max-width: 767px) {
		height: 250px;
	}

}

.team-grid .item .cover {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(31,148,255,0.75);
  transition:opacity 0.15s ease-in;
  opacity:0;
  padding-top:80px;
  color:#fff;
  text-shadow:1px 1px 1px rgba(0,0,0,0.15);
}

.team-grid .item:hover .cover {
  opacity:1;
}

.team-grid .item .name {
  margin-bottom:8px;
}

.team-grid .item .title {
  text-transform:uppercase;
  letter-spacing:2px;
  margin-bottom:20px;
}



.team-grid .social a {
  color:inherit;
  margin:0 10px;
  display:inline-block;
  opacity:0.7;
}

.team-grid .social a:hover {
  opacity:1;
}


/** ARTICLE LIST **/
.article-list {
  background-color:#fff;
}

.article-list h2 {
  margin-bottom:40px;
  padding-top:40px;
}

@media (max-width:767px) {
  .article-list h2 {
    margin-bottom:25px;
    padding-top:25px;
  }
}



.article-list .intro p {
  margin-bottom:0;
}

.article-list .articles {
  padding-bottom:40px;
}

.article-list .item {
  padding-top:50px;
  // min-height:425px;
  text-align:center;
}

.article-list .item .name {
  margin-top:20px;
  color:inherit;
}

.article-list .item .description {
  margin-top:15px;
  margin-bottom:0;
}

.article-list .item .action {
  margin:22px auto 0;
  line-height:1;
  display:block;
  color: $primary;
  opacity:0.85;
  transition:opacity 0.2s;
  text-decoration:none;
}

.article-list .item .action:hover {
  opacity:1;
}

.logo {
	max-width: 200px;
	@media (max-width: 767px) {
		max-width: 155px;
		margin: 0.5em;
	}
}

.navbar-toggle {
	margin-top: 20px;
}

@media (max-width: 991px) {
    footer {
    padding: 1em;
    padding-top: 2em;
  }

footer p, footer a {
    margin-bottom: 0.75em !important;
    font-size: 14px !important;
  }
}

.modal-title {
	color : white;
}